import { sentryConfig as defaultSentryConfig } from './Config.base';
export * from './Config.base';

export const sentryConfig = {
    ...defaultSentryConfig,
    environment: 'live',
    debug: false
};

export const LookUpServerUrl = 'https://api.visionslive.com/VL/focusGroupsRouter/getServerUri?token=';
export const growthbookApiKey = 'sdk-67zepqEqSYZOy1Le';
